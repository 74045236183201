<script>
import { Line } from "vue-chartjs";

export default {
  mixins: [Line],
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    textCenter() {
      this.addPlugin({
        id: "my-plugin",
        beforeDraw(chart) {
          const { width } = chart.chart;
          const { height } = chart.chart;
          const { ctx } = chart.chart;

          ctx.restore();
          const fontSize = (height / 120).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = "middle";

          const text = `No Data`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2.2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      });
    }
  },

  mounted() {
    if(this.value) {
    this.textCenter();
    }
    this.renderChart(this.data, this.options);
  }
};
</script>

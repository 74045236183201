<script>
import { Doughnut } from "vue-chartjs";

export default {
  mixins: [Doughnut],
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    value: {
      type: [String, Number],
      default: "0"
    },
    textHeight: {
      type: Number,
      default: 2.5
    }
  },
  methods: {
    textCenter() {
      const { value } = this;
      this.addPlugin({
        id: "my-plugin",
        beforeDraw(chart) {
          const { width } = chart.chart;
          const { height } = chart.chart;
          const { ctx } = chart.chart;

          ctx.restore();
          const fontSize = (height / 120).toFixed(2);
          ctx.font = `${fontSize}em sans-serif`;
          ctx.textBaseline = "middle";

          const text = `${value}`;
          const textX = Math.round((width - ctx.measureText(text).width) / 2);
          const textY = height / 2.5;

          ctx.fillStyle = "rgba(33, 118, 255, 1)";
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      });
    }
  },

  mounted() {
    if (this.value > 0) {
      this.textCenter();
    }
    this.renderChart(this.data, this.options);
  }
};
</script>

<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex flex-row justify-start items-center gap-4 px-3">
          <h1 class="text-xl text-left font-extrabold">Compensation</h1>
          <breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template>
        <template>
          <c-card class="p-5 mx-3 my-5 flex flex-col flex-wrap gap-5">
            <div v-if="isReviewStatsLoading" style="height:421px">
              <loader size="xxs" :loader-image="false" />
            </div>
            <template v-else>
              <c-card class="px-3 py-7 flex flex-wrap justify-between items-center w-full">
                <p class="text-darkPurple font-bold text-lg leading-5">Cost Insight (Salaries)</p>
                <Divider border-dash class="flex-grow w-auto mx-3" />
                <div class="flex justify-between items-center gap-2">
                  <div class="flex justify-between items-center gap-2">
                    <p class="font-semibold text-xs text-darkPurple">Filter By</p>
                    <Icon icon-name="sort_size" class="w-4 h-4 text-flame" />
                  </div>
                  <div class="text-sm radio_button">
                    <radio-button
                      space-between="mr-2 ml-3 text-darkPurple text-xs flex"
                      v-model="filterBy"
                      :options="[
                        { name: 'Specific Date', value: 'date' },
                        { name: 'Period', value: 'period' },
                      ]"
                    />
                  </div>
                  <template v-if="filterBy === 'period'">
                    <div class="flex justify-between items-center gap-2 w-64">
                      <datepicker
                        v-model="filterByRange.startDate"
                        :disabled-dates="{ from: new Date(filterByRange.endDate) }"
                        placeholder="Year to Date"
                      />
                      <div class="uppercase whitespace-nowrap">
                        <span class="text-romanSilver font-semibold text-xs whitespace-nowrap">-- To --</span>
                      </div>
                      <datepicker
                        v-model="filterByRange.endDate"
                        :disabled-dates="{ to: new Date(filterByRange.startDate) }"
                        placeholder="Year to Date"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex justify-between items-center gap-5  w-64">
                      <datepicker
                        v-model="filterByRange.startDate"
                        :disabled-dates="{ from: new Date(filterByRange.endDate) }"
                        placeholder="Year to Date"
                      />
                    </div>
                  </template>
                </div>
              </c-card>
              <div v-if="isCostInsightLoading" style="height:421px">
                <loader size="xxs" :loader-image="false" />
              </div>
              <div v-else class="w-full bg-ceruleanBlue rounded-md shadow grid grid-cols-3 divide-x py-3 px-5">
                <div class="flex justify-start items-center gap-2">
                  <Icon icon-name="expense-icon" size="s" />
                  <div class="leading-5 flex flex-col justify-start items-start">
                    <p class="font-semibold text-white text-xs uppercase">Top 5 (Annual)</p>
                    <p class="font-bold text-lg text-ghostWhite uppercase">
                      {{ convertToCurrency(costInsights.topFiveCost) }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-start items-center gap-2 px-5">
                  <div class="leading-5 flex flex-col justify-start items-start">
                    <p class="font-semibold text-white text-xs uppercase">Total Annual Compensation Cost</p>
                    <p class="font-bold text-lg text-ghostWhite uppercase">
                      {{ convertToCurrency(costInsights.totalCompensationCost) }}
                    </p>
                  </div>
                </div>
                <div class="flex justify-start items-center gap-2 px-5">
                  <div class="leading-5 flex flex-col justify-start items-start">
                    <p class="font-semibold text-white text-xs uppercase">Total Monthly Compensation Cost</p>
                    <p class="font-bold text-lg text-ghostWhite uppercase">
                      {{ convertToCurrency(costInsights.totalCompensationCost / 12) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </template>
        <template>
          <c-card class="px-3 py-3 mx-3">
            <div v-if="isReviewStatsLoading" style="height:421px">
              <loader size="xxs" :loader-image="false" />
            </div>
            <template v-else>
              <template v-if="costInsights.employees">
                <c-table
                  :headers="employeeHeaders"
                  :items="costInsights.employees"
                  :has-number="false"
                  :loading="isLoading"
                  v-if="costInsights.employees.length"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.paygradeId" class="font-semibold text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">{{ item.data.paygradeId }}</span>
                    </div>
                    <div v-if="item.totalEmployee" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">{{ item.data.totalEmployee }}</span>
                    </div>
                    <div v-if="item.rangeMinimum" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.rangeMinimum) }}
                      </span>
                    </div>
                    <div v-if="item.rangeMaximum" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.rangeMaximum) }}
                      </span>
                    </div>
                    <div v-if="item.average" class="font-normal text-sm leading-5 tracking-widest">
                    <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.average) }}
                      </span>
                    </div>
                    <div v-if="item.totalAmount" class="font-normal text-sm leading-5 tracking-widest">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.totalAmount) }}
                      </span>
                    </div>
                  </template>
                </c-table>
                <div v-else class="flex flex-col justify-center items-center text-center">
                  <Icon icon-name="dashboard-error" size="" class="w-72 h-72" />
                  <p class="text-xl font-bold">No Data Available</p>
                  <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                </div>
              </template>
            </template>
          </c-card>
        </template>
        <Divider border-dash class="flex-grow w-auto mx-3 my-5" />
        <template>
          <div class="w-full flex justify-between items-start gap-3 px-3 compa-ratio">
            <c-card class="px-3 py-3 w-1/3 h-full">
              <div class="flex flex-col items-start gap-5">
                <p class="font-bold text-lg text-darkPurple">Salary Metrics (Compa-ratio)</p>
                <div class="flex justify-between items-center bg-ghostWhite rounded pr-3 h-10 w-full">
                  <search-input placeholder="Search" class="-mt-2" @input="$event => {
                    onGetQueryFunction({ search: $event }, null, getCompensationCompaRatio)}"
                  />
                  <Icon class-name="text-darkPurple" size="xs" icon-name="sort_size" />
                </div>
              </div>
              <div v-if="isCompaRatioLoading" style="height:421px">
                <loader size="xxs" :loader-image="false" />
              </div>
              <template v-else>
                <template v-if="doughnutData.labels.length">
                  <div class="flex flex-col justify-evenly items-center relative py-5">
                    <div class="flex justify-center items-center">
                        <DoughnutChart
                        :options="doughnutOptions"
                        :data="doughnutData"
                        :chart-data="doughnutData"
                        :value="compaRatio.totalEmployees"
                        :styles="{height: '300px', width: '300px', position: 'relative'}"
                      />
                    </div>
                  </div>
                </template>
                <div v-else class="flex flex-col justify-center items-center text-center">
                  <Icon icon-name="dashboard-error" size="" style="width:100%;height:100%" />
                  <p class="text-xl font-bold">No Data Available</p>
                  <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                </div>
              </template>
            </c-card>
            <c-card class="px-3 py-3 pb-3 w-2/3 h-full flex flex-col gap-5">
              <div class="flex justify-between items-center gap-5">
                <p class="font-bold text-lg text-darkPurple">Salary Metrics (Pay Grade)</p>
                <div class="flex justify-between items-center bg-ghostWhite rounded pr-3 h-10">
                  <search-input placeholder="Search" class="-mt-2" @input="$event => {
                    onGetQueryFunction({ search: $event }, null, getCompesatioSalaryMetrics)}"
                  />
                  <Icon class-name="text-darkPurple" size="xs" icon-name="sort_size" />
                </div>
              </div>
              <div v-if="isSalaryMetricsLoading" style="height:421px">
                <loader size="xxs" :loader-image="false" />
              </div>
              <template v-else>
                <div v-if="compaRatioStats.length" class="compa-ratio salary_metrics overflow-auto flex flex-col gap-2">
                  <div class="flex justify-end">
                    <p class="font-black text-xs text-darkPurple">Employee/Compa-ratio Category</p>
                  </div>
                  <c-table
                    class="salary_metrics"
                    :headers="compaRatioHeaders"
                    :items="compaRatioStats"
                    :has-number="false"
                    style="height:421px"
                  >
                    <template v-slot:item="{ item }">
                      <div v-if="item.paygradeId" class="font-semibold text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">{{ item.data.paygradeId }}</span>
                      </div>
                      <div v-if="item.lessThanEighty" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="">{{ item.data.lessThanEighty }}</span>
                      </div>
                      <div v-if="item.greaterLessThan" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="">{{ item.data.greaterLessThan }}</span>
                      </div>
                      <div v-if="item.greaterThanOneTweenty" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="">{{ item.data.greaterThanOneTweenty }}</span>
                      </div>
                    </template>
                  </c-table>
                </div>
                <div v-else class="flex flex-col justify-center items-center text-center">
                  <Icon icon-name="dashboard-error" size="" class="w-72 h-72" />
                  <p class="text-xl font-bold">No Data Available</p>
                  <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                </div>
              </template>
            </c-card>
          </div>
        </template>
        <Divider border-dash class="flex-grow w-auto mx-3 my-5" />
        <template>
          <c-card class="mx-3">
            <div class="px-3 py-3 flex flex-col gap-3 flex-wrap justify-start items-start w-full">
              <template>
                <c-card class="px-5 py-2 flex flex-wrap justify-between items-center w-full">
                  <div class="flex flex-wrap justify-between items-center gap-2 w-full">
                    <p class="font-bold text-lg text-darkPurple capitalize">review summary</p>
                    <template>
                      <Divider border-dash class="flex-grow w-auto mx-2" />
                      <c-card>
                        <c-select
                          class="rm-select-border" variant="rm-select-border"
                          :options="['2021 Annual Review', '2021 Mid-Year Review', '2020 Annual Review']"
                        />
                      </c-card>
                    </template>
                  </div>
                </c-card>
              </template>
              <template>
                <div class="flex justify-between items-center relative w-full">
                  <div class="flex justify-between items-center bg-ghostWhite rounded pr-3 h-10">
                    <search-input placeholder="Search" class="-mt-2" />
                    <Icon class-name="text-darkPurple" size="xs" icon-name="sort_size" />
                  </div>
                  <c-select
                    placeholder="Organisation"
                    class="w-60 text-base text-darkPurple"
                    variant="remove_border"
                    @input="getLastCompensationReviewSummary"
                    :options="compensationTypeOptions"
                    v-model="selectedReviewSummary"
                  />
                </div>
              </template>
              <template>
                <c-card class="w-full" v-if="selectedReviewSummary === 'organisation'">
                  <template v-if="reviewOverviewTable.every(item => item.average > 0)">
                    <c-table
                      :headers="salaryMetricsHeader"
                      :items="reviewOverviewTable"
                      :loading="isReviewSummaryLoading"
                      :has-number="false"
                    >
                      <template v-slot:item="{ item }">
                        <div v-if="item.type" class="font-semibold text-sm tracking-widest">
                          <span class="text-darkPurple capitalize" v-if="item.data.type === 'merit'">
                            Merit (Compensation Based)
                          </span>
                          <span class="text-darkPurple capitalize" v-else>
                            {{ item.data.type.split('_').join(' ') }}
                          </span>
                        </div>
                        <div v-if="item.totalEmployee" class="font-normal text-sm tracking-widest">
                          <span class="text-darkPurple">{{ item.data.totalEmployee }}</span>
                        </div>
                        <div v-if="item.variance" class="font-normal text-sm tracking-widest">
                          <span class="text-darkPurple">
                            {{ convertToCurrency(item.data.variance) }}
                          </span>
                        </div>
                        <div v-if="item.minAdjustment" class="font-normal text-sm tracking-widest">
                          <span class="text-darkPurple">
                            {{ item.data.minAdjustment.toFixed(2) }}%
                          </span>
                        </div>
                        <div v-if="item.maxAdjustment" class="font-normal text-sm tracking-widest">
                          <span class="text-darkPurple">
                            {{ item.data.maxAdjustment.toFixed(2) }}%
                          </span>
                        </div>
                        <div v-if="item.average" class="font-normal text-sm tracking-widest">
                          <span class="text-darkPurple">
                            {{ item.data.average.toFixed(2) }}%
                          </span>
                        </div>
                      </template>
                    </c-table>
                    <template v-if="!isReviewSummaryLoading">
                      <div class="flex justify-center items-center w-full my-2 -ml-24">
                        <span class="text-lg font-bold text-darkPurple ml-7">
                          {{ convertToCurrency(sumReviewOverview) }}
                        </span>
                      </div>
                    </template>
                  </template>
                  <div v-else class="flex flex-col justify-center items-center text-center pb-5">
                    <p class="font-bold text-lg text-darkPurple capitalize flex self-start p-3">review summary</p>
                    <Icon icon-name="dashboard-error" size="" class="w-72 h-72" />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                  </div>
                </c-card>
                <c-card v-else class="px-2 py-2 flex flex-col w-full">
                  <c-table
                    :headers="reviewTypeOverviewHeaders"
                    :items="reviewTypeOverviewTable"
                    :loading="isReviewTypeLoading"
                    v-if="reviewTypeOverviewTable.length"
                    :has-number="false"
                    class="overflow-auto"
                    style="height:350px"
                  >
                    <template v-slot:item="{ item }">
                      <div v-if="item.totalEmployee" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">{{ item.data.totalEmployee }}</span>
                      </div>
                      <div v-if="item.paygradeId" class="font-semibold text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">{{ item.data.paygradeId }}</span>
                      </div>
                      <div v-if="item.minAdjustment" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ item.data.minAdjustment.toFixed(2) }}%
                        </span>
                      </div>
                      <div v-if="item.maxAdjustment" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ item.data.maxAdjustment.toFixed(2) }}%
                        </span>
                      </div>
                      <div v-if="item.average" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ item.data.average.toFixed(2) }}%
                        </span>
                      </div>
                      <div v-if="item.variance" class="font-normal text-sm leading-5 tracking-widest">
                        <span class="text-darkPurple">
                          {{ convertToCurrency(item.data.variance) }}
                        </span>
                      </div>
                    </template>
                  </c-table>
                  <div v-else class="flex flex-col justify-center items-center text-center">
                    <Icon icon-name="dashboard-error" size="" class="w-72 h-72" />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                  </div>
                </c-card>
              </template>
            </div>
          </c-card>
        </template>
        <Divider border-dash class="flex-grow w-auto mx-3 my-5" />
        <c-card class="mx-3 my-5 p-5 flex flex-col justify-between items-start gap-5">
          <div class="w-full flex flex-col gap-3">
            <div class="flex flex-col justify-start items-start gap-0">
              <p class="font-bold text-darkPurple text-lg">Compensation Cost Trend</p>
            </div>
            <template>
              <!-- <div class="flex justify-between gap-5 relative">
                <div class="flex justify-between items-center bg-ghostWhite px-2 rounded h-10">
                  <SearchInput
                    placeholder="Search" class="-mt-2"
                    @input="$event => {
                    onGetQueryFunction({ search: $event }, null, getCompensationCostTrends)}"
                  />
                  <Icon class-name="text-darkPurple" size="xs" icon-name="sort_size" />
                </div>
                <c-card>
                  <c-select
                    class="rm-select-border" variant="rm-select-border"
                    :options="['Employment Type', 'Supervisory Role']"
                    v-model="activeCostTrend"
                    @input="$event => {
                    onGetQueryFunction({ filterBy: $event }, null, getCompensationCostTrends)}"
                  />
                </c-card>
              </div> -->
              <div style="height:300px" v-if="isLoadingCostTrends">
                <loader size="xxs" :loader-image="false" />
              </div>
              <div v-else class="flex flex-col justify-start gap-2">
                <template v-if="costTrendsChartData.labels.length">
                  <div class="flex flex-col justify-center items-center py-5 px-2 gap-5">
                    <LineChart
                      :options="costTrendsChartOptions"
                      :chart-data="costTrendsChartData"
                      :data="costTrendsChartData"
                      :styles="{height: '400px', width: '100%', position: 'relative'}"
                    />
                  </div>
                </template>
                <template v-else>
                  <div class="flex flex-col justify-center items-center text-center w-full h-full">
                    <Icon icon-name="dashboard-error" style="width:300px;height:400px" />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </c-card>
      </template>
    </template>
  </div>
</template>

<script>
  import Datepicker from "vue2-datepicker"
  import 'vue2-datepicker/index.css'
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
  import CCard from "@scelloo/cloudenly-ui/src/components/card"
  import CSelect from "@scelloo/cloudenly-ui/src/components/select"
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio"
  import CTable from "@scelloo/cloudenly-ui/src/components/table"
  import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
  import Icon from "@/components/Icon"
  import Divider from "@/components/divider"
  import DoughnutChart from "@/components/donutChart"
  import LineChart from "@/components/LineChart"

  export default {
    components: {
      Datepicker,
      Breadcrumb,
      CCard,
      CTable,
      CSelect,
      RadioButton,
      SearchInput,
      Icon,
      Divider,
      DoughnutChart,
      LineChart
    },
    data(){
      return {
        isLoading: true,
        isReviewStatsLoading: true,
        isCompaRatioLoading: true,
        isReviewTypeLoading: true,
        isReviewSummaryLoading: false,
        isCostInsightLoading: false,
        isLoadingCostTrends: false,
        isSalaryMetricsLoading: false,
        setQueryTimeout: null,
        selectedReviewSummary: 'organisation',
        reviewSummary: {},
        costInsights: {},
        activeCostTrend: '',
        durationType: 'year',
        filterByRange: { startDate: '', endDate: '' },
        filterBy: 'period',
        filterStartDate: '',
        filterEndDate: '',
        compaRatioStats: [],
        reviewOverviewTable: [],
        reviewTypeOverviewTable: [],
        sumReviewOverview: '',
        compaRatio: {},
        costTrendsChartData: { legends: [], labels: [] },
        costTrendsChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true, position: "bottom",
            labels: {usePointStyle: true, fontSize: 12, fontColor: '#333333'}
          },
          scales: {
            xAxes: [{
              ticks: {beginAtZero: true},
              scaleLabel: {display: true, labelString: 'Year'},
              gridLines: {display: false},
            }],
            yAxes: [{
              scaleLabel: {display: true, labelString: 'Cost(₦)'},
              gridLines: {display: true, color: '#878E99', borderDash: [4, 4]},
            }]
          },
        },
        breadcrumbs: [
          { disabled: false, text: 'Analytics', href: 'Analytics', id: 'Analytics' },
          { disabled: false, text: 'Compensation', href: 'Compensation', id: 'Compensation' }
        ],
        compensationTypeOptions: [
          { name: 'Organisation', id: 'organisation', value: 'organisation' },
          { name: 'Merit (Performance Based)', id: 'merit', value: 'merit' },
          { name: 'Promotion', id: 'promote', value: 'promote' },
          { name: 'Discretionary Adjustment', id: 'discretionary_adjustment', value: 'discretionary_adjustment' },
          { name: 'Lump Sum Award', id: 'lump_sum_award', value: 'lump_sum_award' },
        ],
        doughnutOptions: {
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 10,
              fontColor: '#333333'
            }
          },
          responsive: true,
          cutoutPercentage: 56,
          maintainAspectRatio: false
        },
        doughnutData: {
          labels: [],
          datasets: [{  backgroundColor: [], data: [] }]
        },
        employeeHeaders: [
          { title: "PAY GRADE", value: "paygradeId" },
          { title: "NO OF EMPLOYEE", value: "totalEmployee" },
          { title: "ANNUAL RANGE MINIMUM", value: "rangeMinimum" },
          { title: "ANNUAL RANGE MAXIMUM", value: "rangeMaximum" },
          { title: "AVERAGE", value: "average" },
          { title: "TOTAL AMOUNT (NGN)", value: "totalAmount" },
        ],
        compaRatioHeaders: [
          { title: "PAY GRADE", value: "paygradeId" },
          { title: "≤80%", value: "lessThanEighty" },
          { title: ">80% but <120%", value: "greaterLessThan" },
          { title: "≥120%", value: "greaterThanOneTweenty" },
        ],
        salaryMetricsHeader: [
          { title: "compensation review basis", value: "type" },
          { title: "no. of employees", value: "totalEmployee" },
          { title: "total compensation variance", value: "variance" },
          { title: "min. adjustment", value: "minAdjustment" },
          { title: "max. adjustment", value: "maxAdjustment" },
          { title: "Average", value: "average" },
        ],
        reviewTypeOverviewHeaders: [
          { title: "no. of employees", value: "totalEmployee" },
          { title: "pay grade", value: "paygradeId" },
          { title: "min. adjustment", value: "minAdjustment" },
          { title: "max. adjustment", value: "maxAdjustment" },
          { title: "Average", value: "average" },
          { title: "total compensation variance", value: "variance" },
        ],
      }
    },
    watch: {
      filterByRange: {
        deep: true,
        handler(value){
          if (this.filterBy === 'period') {
            if (value.startDate && value.endDate) {
              this.getCompensationPaygradeOverview({ from: value.startDate, to: value.endDate })
            }
          } else if (this.filterBy === 'date') {
            this.getCompensationPaygradeOverview({ from: value.startDate })
          }
        }
      },
    },
    methods: {
      onGetQueryFunction(queryParams, payload, callback){
        clearTimeout(this.setQueryTimeout);
        this.setQueryTimeout = setTimeout(() => {
          if(queryParams === undefined){
            this.queryParams = {}
            return callback({ ...this.queryParams, ...payload })
          }
          this.queryParams = {...this.queryParams, ...queryParams}
          return callback({ ...this.queryParams, ...payload })
        }, 1000);
      },
      onChangeCostInsights(durationType){
        this.getCompensationPaygradeOverview(durationType)
      },
      getCompensationPaygradeOverview(queryParams){
        this.isCostInsightLoading = true
        this.$_getCompensationPaygradeOverview(queryParams).then(({ data }) => {
          this.costInsights = data
          this.costInsights.employees = data.employees.map((employee) => ({
            ...employee,
            id: employee.paygrade.id,
            paygradeId: employee.paygrade.paygradeId,
            rangeMinimum: employee.paygrade.rangeMinimum,
            rangeMaximum: employee.paygrade.rangeMaximum,
            rangeMidPoint: employee.paygrade.rangeMidPoint,
          }))
          this.isCostInsightLoading = false
        }).catch(() => {
          this.costInsights = {}
          this.isCostInsightLoading = false
        })
      },
      getCompensationReviewSummary(){
        this.isReviewStatsLoading = true
        this.$_getCompensationReviewSummary().then(({ data }) => {
          this.reviewSummary = data.data
          this.isReviewStatsLoading = false
        }).catch(() => {
          this.reviewSummary = {}
          this.isReviewStatsLoading = false
        })
      },
      async getCompesatioSalaryMetrics(payload){
        this.isSalaryMetricsLoading = true
        await this.$_getCompensationSalaryMetrics(payload).then(({ data }) => {
          const compaRatio = {
            greaterLessThan: 0,
            lessThanEighty: 0,
            greaterThanOneTweenty: 0,
          }
          data.compaRatioStats.forEach(item => {
            if(item.greaterThanEightyButLessOneTweenty) {
              compaRatio.greaterLessThan += item.greaterThanEightyButLessOneTweenty
            }
            if(item.lessThanEighty) {
              compaRatio.lessThanEighty += item.lessThanEighty
            }
            if(item.greaterThanOneTweenty) {
              compaRatio.greaterThanOneTweenty += item.greaterThanOneTweenty
            }
          })

          const compaRatioStats = data.compaRatioStats?.map((stats) => ({
            greaterLessThan: stats.greaterThanEightyButLessOneTweenty,
            lessThanEighty: stats.lessThanEighty,
            paygradeId: stats.grade,
            greaterThanOneTweenty: stats.greaterThanOneTweenty,
          }))
          if(compaRatioStats !== null){
            this.compaRatioStats = compaRatioStats
            this.compaRatioStats.push({ paygradeId: 'Total', ...compaRatio })
          }
          this.isSalaryMetricsLoading = false
        })
      },
      async getCompensationCompaRatio(payload){
        this.isCompaRatioLoading = true
        await this.$_getCompensationRatioMetrics(payload).then(({ data }) => {
          this.compaRatio = {
            greaterLessThan: data.greaterThanEightyButLessOneTweenty,
            lessThanEighty: data.lessThanEighty,
            greaterThanOneTweenty: data.greaterThanOneTweenty,
            totalEmployees: data.totalEmployees,
          }
          const doughnutData = [
            this.compaRatio.greaterLessThan,
            this.compaRatio.greaterThanOneTweenty,
            this.compaRatio.lessThanEighty,
          ]
          if(doughnutData.some(el => el > 0)){
            const bgColor = ['#EA3C53', '#2176FF', '#13B56A']
            this.doughnutData = {
              labels: ['≤80%', '>80% but <120%', '≥120%'],
              datasets: [{backgroundColor: bgColor, data: doughnutData}]
            }
          }
          this.isCompaRatioLoading = false
        })
      },
      async getCompensationCostTrends(queryParams){
        this.isLoadingCostTrends = true
        await this.$_getCompensationCostTrends(queryParams).then(({ data }) => {
          const trendByYearsLabel = data.employeesByYear?.map(item => item.year)
          const hourlyTotalCost = data.employeesByYear?.map(item => item.hourlyTotalCost)
          const salariedTotalCost = data.employeesByYear?.map(item => item.salariedTotalCost)

          this.costTrendsChartData = {
            labels: trendByYearsLabel,
            datasets: [
              {
                label: 'Salaried Employees',
                data: hourlyTotalCost,
                fill: true,
                borderColor: '#E99323',
                backgroundColor: 'rgba(247, 152, 36, 0.08)',
                borderWidth: 2
              },
              {
                label: 'Hourly Employees',
                data: salariedTotalCost,
                fill: true,
                borderColor: '#2176FF',
                backgroundColor: 'rgba(53, 125, 237, 0.08)',
                borderWidth: 2
              }
            ],
          }
           this.isLoadingCostTrends = false
        })
      },
      async getLastCompensationReviewSummary(reviewType){
        const compensationReviewOverview = async () => {
          await this.$_getCompensationReviewOverview().then(({ data }) => {

            this.reviewOverviewTable = [
              data.data.meritData,
              data.data.awardedData,
              data.data.promotionData,
              data.data.discretionalData,
            ]
            this.sumReviewOverview = ([
              data.data.discretionalData.variance,
              data.data.promotionData.variance,
              data.data.awardedData.variance,
              data.data.meritData.variance
            ].reduce((a, b) => a + b, 0))
          })
        }
        const compensationReviewTypes = async (reviewTypeId) => {
          await this.$_getCompensationReviewTypes(reviewTypeId).then(({ data }) => {
            this.reviewTypeOverviewTable = data?.data.map((review) => ({
              ...review.data, paygradeId: review.paygrade,
            }))
          })
        }

        this.isReviewTypeLoading = true
        this.isReviewSummaryLoading = true

        if(reviewType === 'organisation'){
          await compensationReviewOverview()
        } else await compensationReviewTypes(reviewType)

        this.isReviewTypeLoading = false
        this.isReviewSummaryLoading = false
      },
    },
    created(){
      this.isLoading = true

      this.getCompensationPaygradeOverview()
      this.getCompensationReviewSummary()
      this.getCompensationCompaRatio({})
      this.getLastCompensationReviewSummary('organisation')
      this.getCompesatioSalaryMetrics({})
      this.getCompensationCostTrends('')

      this.isLoading = false

    }
  }
</script>

<style lang="scss">
  .rm-select-border {
    border: 0 !important;
  }
  .iconSize {
    width: 20px;
    height: 20px;
  }
  .remove_border {
    border: none !important;
  }
  .salary_metrics {
    th:nth-child(2){
      color: #EA3C53;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    th:nth-child(3){
      color: #2176FF;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    th:nth-child(4){
      color: #13B56A;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    td:nth-child(2){
      color: #EA3C53;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    td:nth-child(3){
      color: #2176FF;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    td:nth-child(4){
      color: #13B56A;
      background: #EDEDED;
      border: 1px solid #878E99;
    }
    tbody {
      tr:nth-last-child(-n+2){
        span {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
  .inner-donut-function {
    display: flex;
    align-items:center;
    justify-content: center;
    position: relative;
    left: 222px;
    top: -15px;
  }
  .text-10 {
    font-size: 10px !important;
  }
  .text-c-white {
    color: white !important;
  }
  .compa-ratio {
    height: 440px !important;
  }
  .mx-input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .datepicker-input {
    font-size: 14px;
    width: 6rem;
    outline: none;
  }
  .radio_button div {
    display: flex;
    margin-right: 5px;
  }
  .mx-input {
    box-shadow: none !important;
    border: none !important;
    width: 100% !important;
  }
</style>
